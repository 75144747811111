/* li .active {
  color: white;
} */


header{
  background-color: black;
}
.main-menu__btn-box {
  position: relative;
  display: block;
  transform: translate(15%, 30%);

}

.main-menu__btn {
  padding: 14px 31px 12px;
  background-color: #fdcb32;
  color: black;
  border-radius: 16px;
}

.main-menu__btn-box:after {
  border-radius: 26px;
  background: white;
}

.main-menu__btn:hover {
  color: white;
}

.call{
  margin-right: 5px;
}

/* #fdcb32 */
/* #d4a107 */
.pp-7G2HW5YCMYVTU  {
    text-align:center;
    border:none;border-radius:1.5rem;
    min-width:11.625rem;
    padding:0 2rem;
    height:2.625rem;
    font-weight:bold;
    background-color:#FFD140;
    color:#000000;
    font-family:"Helvetica Neue",Arial,sans-serif;
    font-size:1rem;
    line-height:1.25rem;
    cursor:pointer;
}
